export class PageConfig {
  currentPage: number;
  itemsPerPage: number;
  maxSize: number;
  boundaryLinks: boolean;

  constructor(init?: Partial<PageConfig>) {
    this.currentPage = 1;
    this.itemsPerPage = 10;
    this.maxSize =10;
    Object.assign(this, init);
  }
}
