export class Test {
  TestID: number;
  Name: string;
  Code?: string;
  DeptID?: number;
  DeptName?: string;
  RefRangeID?: number;
  RefRange?: string;
  UnitID?: number;
  Unit?: string;
  SpecimenID?: number;
  MethodID?: number;
  Amt?: number;
  Flag?: string;
  LabReqId?: number;
  Result?: string;
  Formula?: string;
  FormulaFullDesc?: string;
}
