import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilityService } from './utility.service';
import { ApiParams } from '../models';
import { forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MasterService {
  private baseUrl = '';
  constructor(
    private util: UtilityService,
    private http: HttpClient,
    @Inject('BASE_URL') baseURL: string
  ) {
    this.baseUrl = baseURL;
  }

  getDoctorsList(info) {
    const httpOptions = this.util.getMappedHttpOptions(
      info,
      ApiParams.getDoctors
    );
    return this.http.request('POST', this.baseUrl, httpOptions);
  }

  getDoctor(info) {
    const httpOptions = this.util.getMappedHttpOptions(
      info,
      ApiParams.getDoctor
    );
    return this.http.request('POST', this.baseUrl, httpOptions);
  }

  // SAVE, UPDATE,DELETE
  setDoctorData(info: any) {
    const httpOptions = this.util.getMappedHttpOptions(
      info,
      ApiParams.setDoctorData
    );
    return this.http.request('POST', this.baseUrl, httpOptions);
  }

  // Patients
  getPatientsList(info) {
    const httpOptions = this.util.getMappedHttpOptions(
      info,
      ApiParams.getPatients
    );
    return this.http.request('POST', this.baseUrl, httpOptions);
  }

  getPatient(info) {
    const httpOptions = this.util.getMappedHttpOptions(
      info,
      ApiParams.getPatient
    );
    return this.http.request('POST', this.baseUrl, httpOptions);
  }

  // SAVE, UPDATE,DELETE
  setPatientData(info: any) {
    const httpOptions = this.util.getMappedHttpOptions(
      info,
      ApiParams.setPatientData
    );
    return this.http.request('POST', this.baseUrl, httpOptions);
  }

  // Staffs
  addStaff(obj: any) {
    // TODO : Need to replace this call with acutal data
    return this.http.post(this.baseUrl + 'post url', obj);
  }
  getStaffList(info: any) {
    // TODO : Need to replace this call with acutal data
    const httpOptions = this.util.getMappedHttpOptions(
      info,
      ApiParams.getStaffs
    );
    return this.http.request('POST', this.baseUrl, httpOptions);
  }

  deleteStaff(id: number) {
    // TODO : Need to replace this call with acutal data
    return this.http.post(this.baseUrl + 'post url', id);
  }

  // department
  addDepartment(obj: any) {
    // TODO : Need to replace this call with acutal data
    return this.http.post(this.baseUrl + 'post url', obj);
  }
  deleteDepartment(id: number) {
    // TODO : Need to replace this call with acutal data
    return this.http.post(this.baseUrl + 'post url', id);
  }

  // unit
  addUnit(obj: any) {
    // TODO : Need to replace this call with acutal data
    return this.http.post(this.baseUrl + 'post url', obj);
  }
  deleteUnit(id: number) {
    // TODO : Need to replace this call with acutal data
    return this.http.post(this.baseUrl + 'post url', id);
  }

  // test method
  addTestMethod(obj: any) {
    // TODO : Need to replace this call with acutal data
    return this.http.post(this.baseUrl + 'post url', obj);
  }
  deleteTestMethod(id: number) {
    // TODO : Need to replace this call with acutal data
    return this.http.post(this.baseUrl + 'post url', id);
  }

  // specimen
  addSpecimen(obj: any) {
    // TODO : Need to replace this call with acutal data
    return this.http.post(this.baseUrl + 'post url', obj);
  }
  deleteSpecimen(id: number) {
    // TODO : Need to replace this call with acutal data
    return this.http.post(this.baseUrl + 'post url', id);
  }

  // Test Profile
  addTestProfile(obj: any) {
    // TODO : Need to replace this call with acutal data
    return this.http.post(this.baseUrl + 'post url', obj);
  }
  deleteTestProfile(id: number) {
    // TODO : Need to replace this call with acutal data
    return this.http.post(this.baseUrl + 'post url', id);
  }

  // Test
  getTestList(info) {
    const httpOptions = this.util.getMappedHttpOptions(
      info,
      ApiParams.getTests
    );
    return this.http.request('POST', this.baseUrl, httpOptions);
  }

  getTest(info) {
    const httpOptions = this.util.getMappedHttpOptions(
      info,
      ApiParams.getTestData
    );
    return this.http.request('POST', this.baseUrl, httpOptions);
  }

  // SAVE, UPDATE,DELETE
  setTestData(info: any) {
    const httpOptions = this.util.getMappedHttpOptions(
      info,
      ApiParams.setTestData
    );
    return this.http.request('POST', this.baseUrl, httpOptions);
  }

  getDDValuesforNewTest() {
    const httpOptions = this.util.getMappedHttpOptions(
      {},
      ApiParams.getDDValuesforNewTest
    );
    return this.http.request('POST', this.baseUrl, httpOptions);
  }

  // Lookups
  getLookupData(info) {
    const httpOptions = this.util.getMappedHttpOptions(
      info,
      ApiParams.getLookupData
    );
    return this.http.request('POST', this.baseUrl, httpOptions);
  }

  setLookupData(info) {
    const httpOptions = this.util.getMappedHttpOptions(
      info,
      ApiParams.setLookupData
    );
    return this.http.request('POST', this.baseUrl, httpOptions);
  }

  setProfileDataPrice(info: any) {
    const httpOptions = this.util.getMappedHttpOptions(
      info,
      ApiParams.setProfilePrice
    );
    return this.http.request('POST', this.baseUrl, httpOptions);
  }

  getTestAndProfileList() {
    const testReqOptions = this.util.getMappedHttpOptions(
      {},
      ApiParams.getTests
    );
    const testResponse = this.http.request(
      'POST',
      this.baseUrl,
      testReqOptions
    );

    const profileReqOptions = this.util.getMappedHttpOptions(
      {},
      ApiParams.getProfiles
    );
    const profileResponse = this.http.request(
      'POST',
      this.baseUrl,
      profileReqOptions
    );

    return forkJoin([testResponse, profileResponse]);
  }

  // Package
  getPackageList(info: any) {
    // TODO : Need to replace this call with acutal data
    const httpOptions = this.util.getMappedHttpOptions(
      info,
      ApiParams.getPackages
    );
    return this.http.request('POST', this.baseUrl, httpOptions);
  }

  setPackageData(info: any) {
    const httpOptions = this.util.getMappedHttpOptions(
      info,
      ApiParams.setPackage
    );
    return this.http.request('POST', this.baseUrl, httpOptions);
  }
}
