import { CMS_ROUTES } from "./appConstants";

export class PatientVisitsStatus {
  static readonly OPEN = 'Open';
  static readonly INVOICE_GENERATED = 'Invoice Generated';
  static readonly SAMPLE_COLLECTED = 'Sample Collected';
  static readonly RESULT_ENTERED = 'Result Entered';
  static readonly RESULT_APPROVED = 'Result Approved';
  static readonly REPORT_GENERATED = 'Report Generated';

  static values: Map<string, any> = new Map([
    [PatientVisitsStatus.OPEN, { statusId: 1, color: 'basic',
      showOn:[CMS_ROUTES.DASHBOARD,CMS_ROUTES.PATIENT_VISITS] }],

    [PatientVisitsStatus.INVOICE_GENERATED, { statusId: 10, color: 'control',
      showOn:[CMS_ROUTES.DASHBOARD,CMS_ROUTES.PATIENT_VISITS, CMS_ROUTES.INVOICES, CMS_ROUTES.SAMPLE_COLLECTION] }],

    [PatientVisitsStatus.SAMPLE_COLLECTED, { statusId: 2, color: 'info' ,
      showOn:[CMS_ROUTES.DASHBOARD,CMS_ROUTES.PATIENT_VISITS, CMS_ROUTES.INVOICES, CMS_ROUTES.SAMPLE_COLLECTION, CMS_ROUTES.RESULTS_ENTRY ]}],

    [PatientVisitsStatus.RESULT_ENTERED, { statusId: 3, color: 'warning',
      showOn:[CMS_ROUTES.DASHBOARD, CMS_ROUTES.PATIENT_VISITS, CMS_ROUTES.INVOICES, CMS_ROUTES.RESULTS_ENTRY, CMS_ROUTES.RESULT_APPROVAL] }],

    [PatientVisitsStatus.RESULT_APPROVED, { statusId: 11, color: 'primary',
      showOn:[CMS_ROUTES.DASHBOARD,CMS_ROUTES.PATIENT_VISITS, CMS_ROUTES.INVOICES, CMS_ROUTES.RESULT_APPROVAL, CMS_ROUTES.LAB_REPORT] }],

    [PatientVisitsStatus.REPORT_GENERATED, { statusId: 12, color: 'success',
      showOn:[CMS_ROUTES.DASHBOARD,CMS_ROUTES.PATIENT_VISITS, CMS_ROUTES.INVOICES, CMS_ROUTES.LAB_REPORT] }]
  ]);
}