export class TestProfile {
  ID: number;
  Name: string;
  Code: string;
  TestList: any[];
  CreatedBy: string;
  CreatedOn: Date;
  Flag: string;
  ProfileAmount: number;
  CSVstring: string;
}
