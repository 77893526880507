import { Component } from '@angular/core';
import { NbIconLibraries } from '@nebular/theme';
import { SharedDataService } from './core/services';

@Component({
  selector: 'app-root',
  template: `<div
    [nbSpinner]="sharedDataService.isLoading"
    nbSpinnerStatus="info"
    nbSpinnerSize="giant"
  >
    <router-outlet></router-outlet>
  </div>`,
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'clinicNew';

  constructor(
    private iconsLibrary: NbIconLibraries,
    public sharedDataService: SharedDataService
  ) {
    this.iconsLibrary.registerFontPack('fa', {
      packClass: 'fa',
      iconClassPrefix: 'fa',
    });
    this.iconsLibrary.registerFontPack('fas', {
      packClass: 'fas',
      iconClassPrefix: 'fa',
    });
    // this.iconsLibrary.registerFontPack('eva', { packClass: 'eva',iconClassPrefix: 'eva' });
    // // this.iconsLibrary.registerFontPack('fas', { packClass: 'fas', iconClassPrefix: 'fas' });

    this.iconsLibrary.setDefaultPack('fa');
  }
}
