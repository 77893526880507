import { AnalysisItemType } from './analysis-item-types';
import { ItemsOrderSequence } from './items-order-sequence';

export class ItemModel {
  ItemType: AnalysisItemType;
  ID: number;
  Code: string;
  Text: string;
  CodeAndText: string;
  Amount: number;
  TstIdsCSV?: string; //if not null means its test profile item
  ProfIdsCSV?: string; //if not null means its package item
  TstRefRange: string;
  TstUnit: string;
  SpecId?: number;
  Specimen?: string;
  Formula?: string;
  ItemsOrderSeq?: ItemsOrderSequence[]; //for keeping package items order

  constructor(init?: Partial<ItemModel>) {
    Object.assign(this, init);
  }
}
