import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbAuthModule, NbPasswordAuthStrategy } from '@nebular/auth';
import { NbSecurityModule, NbRoleProvider } from '@nebular/security';
import { of as observableOf } from 'rxjs';

import { throwIfAlreadyLoaded } from './module-import-guard';
import { LayoutService, StateService } from './utils';
import {
  NbCalendarModule,
  NbCalendarYearModelService,
  NbDatepickerModule,
  NbDialogModule,
  NbDialogService,
  NbMenuModule,
  NbSidebarModule,
  NbToastrModule,
} from '@nebular/theme';
import { environment } from '../../environments/environment';
import { NbDateFnsDateModule } from '@nebular/date-fns';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

export class NbSimpleRoleProvider extends NbRoleProvider {
  getRole() {
    // here you could provide any role based on any auth flow
    return observableOf('guest');
  }
}

export const NB_CORE_PROVIDERS = [
  ...(NbAuthModule.forRoot({
    strategies: [
      NbPasswordAuthStrategy.setup({
        name: 'email',
        baseEndpoint: environment.serverUrl,
        login: {
          endpoint: '___PARAM_Class=VST000BO&___PARAM_Method=StartSession',
          method: 'post',
          redirect: {
            success: '/home/dashboard',
            failure: null, // stay on the same page
          },
        },
        token: {
          key: 'SessionID',
        },
      }),
    ],
    forms: {
      login: {
        redirectDelay: 0,
        showMessages: {
          success: true,
        },
      },
    },
  }).providers || []),
  NbSecurityModule.forRoot({
    accessControl: {
      guest: {
        view: '*',
      },
      user: {
        parent: 'guest',
        create: '*',
        edit: '*',
        remove: '*',
      },
    },
  }).providers || [],
  {
    provide: NbRoleProvider,
    useClass: NbSimpleRoleProvider,
  },
  { provide: 'BASE_URL', useFactory: baseURLFactory },

  NbMenuModule.forRoot().providers || [],
  NbSidebarModule.forRoot().providers || [],
  NbDatepickerModule.forRoot().providers || [],
  NbDateFnsDateModule.forRoot({ format: 'dd/MMM/yyyy' }).providers || [],
  NbToastrModule.forRoot().providers || [],
  TypeaheadModule.forRoot().providers || [],
  LayoutService,
  StateService,
];

@NgModule({
  imports: [CommonModule],
  exports: [NbAuthModule],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [...NB_CORE_PROVIDERS],
    };
  }
}

export function baseURLFactory() {
  // return 'http://172.20.163.146:9080/HRMS/api/';
  return environment.serverUrl;
}
