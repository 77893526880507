export enum MODE {
  SAVE = 'SAVE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  VIEW = 'VIEW',
}

export class ApiParams {
  static login: any = {
    pClass: 'VST000BO',
    pMethod: 'StartSessionMenus',
  };
  static logout: any = {
    pClass: 'VST000BO',
    pMethod: 'StopSession',
  };
  // Doctor
  static getDoctors: any = {
    pClass: 'CMS003HrBO',
    pMethod: 'GetDoctorList',
  };

  static getDoctor: any = {
    pClass: 'CMS003HrBO',
    pMethod: 'GetDoctorData',
  };
  static setDoctorData: any = {
    pClass: 'CMS003HrBO',
    pMethod: 'SetDoctorData',
  };

  // Test profile
  // static getDoctor: any = {
  //     pClass: 'CMS003HrBO',
  //     pMethod: 'GetDoctorData'
  // }

  // Patient
  static getPatients: any = {
    pClass: 'CMS001PatBO',
    pMethod: 'GetPatientList',
  };
  static getPatient: any = {
    pClass: 'CMS001PatBO',
    pMethod: 'GetPatientData',
  };
  static setPatientData: any = {
    pClass: 'CMS001PatBO',
    pMethod: 'SetPatientData',
  };

  // Lab Request
  static getLabReqsList: any = {
    pClass: 'CMS002LabBO',
    pMethod: 'GetLabReqsList',
  };
  static getDDValuesforTestByProfID: any = {
    pClass: 'CMS002LabBO',
    pMethod: 'GetDDValuesforTestByProfID',
  };
  static setPatientVisit: any = {
    pClass: 'CMS002LabBO',
    pMethod: 'SetLabRequest',
  };
  static getDDValuesforNewPatientVisit: any = {
    pClass: 'CMS002LabBO',
    pMethod: 'GetDDValuesforNewLabReq',
  };
  static getLabReqDataByID: any = {
    pClass: 'CMS002LabBO',
    pMethod: 'GetLabReqDataByID',
  };
  static setLabRequestResult: any = {
    pClass: 'CMS002LabBO',
    pMethod: 'SetLabRequestResult',
  };
  static getAllReqStatus: any = {
    pClass: 'CMS002LabBO',
    pMethod: 'GetGenericStatusByID',
  };
  static setPatientVisitStatus: any = {
    pClass: 'CMS002LabBO',
    pMethod: 'setLabrequestStatus',
  };
  static setLabRequestSampleCollected: any = {
    pClass: 'CMS002LabBO',
    pMethod: 'setLabreqSampleCollect',
  };
  static setTestResultsApproval: any = {
    pClass: 'CMS002LabBO',
    pMethod: 'SetTestResultsApproval',
  };

  // Test
  static getTests: any = {
    pClass: 'CMS004OpnBO',
    pMethod: 'GetTestsList',
  };
  static getTestData: any = {
    pClass: 'CMS004OpnBO',
    pMethod: 'GetTestData',
  };
  static setTestData: any = {
    pClass: 'CMS004OpnBO',
    pMethod: 'SetTestData',
  };
  static getDDValuesforNewTest: any = {
    pClass: 'CMS004OpnBO',
    pMethod: 'GetDDValuesforNewTest',
  };

  // lookups
  static getLookupData: any = {
    pClass: 'CMS004OpnBO',
    pMethod: 'GetLUTableList',
  };
  static setLookupData: any = {
    pClass: 'CMS004OpnBO',
    pMethod: 'SetLUTableData',
  };

  // test results
  static getLabResultReportData: any = {
    pClass: 'CMS004OpnBO',
    pMethod: 'GetLabResultReport',
  };

  //staff
  static getStaffs: any = {
    pClass: 'CMS003HrBO',
    pMethod: 'GetStaffList',
  };
  static getStaff: any = {
    pClass: 'CMS003HrBO',
    pMethod: 'GetStaffData',
  };
  static setStaffData: any = {
    pClass: 'CMS003HrBO',
    pMethod: 'SetStaffData',
  };

  // profiles
  static getProfiles: any = {
    pClass: 'CMS004OpnBO',
    pMethod: 'GetAllProfileList',
  };

  static setProfilePrice: any = {
    pClass: 'CMS004OpnBO',
    pMethod: 'SetProfileDataPrice',
  };

  // package
  static getPackages: any = {
    pClass: 'CMS004OpnBO',
    pMethod: 'GetPackageDataById',
  };

  static setPackage: any = {
    pClass: 'CMS004OpnBO',
    pMethod: 'SetPackageData',
  };

  //Dashboard
  static getFinanceData: any = {
    pClass: 'CMS005DashBO',
    pMethod: 'GetFinanceData',
  };

  // Clinic-info
  static getClinicInfo: any = {
    pClass: 'CMS005DashBO',
    pMethod: 'GetClinicInfo',
  };

  static setClinicInfo: any = {
    pClass: 'CMS005DashBO',
    pMethod: 'SetClinicInfo',
  };

  // User Roles
  static getUserRoles: any = {
    pClass: 'CMS003HrBO',
    pMethod: 'GetUsrRoleList',
  };

  static setUserRoles: any = {
    pClass: 'CMS003HrBO',
    pMethod: 'SetUserRole',
  };

  // User Menus
  static getAllMenus: any = {
    pClass: 'CMS004OpnBO',
    pMethod: 'GetCMSMenus',
  };

  // User
  static setUser: any = {
    pClass: 'CMS003HrBO',
    pMethod: 'SetLoginData',
  };

  static getUsers: any = {
    pClass: 'CMS003HrBO',
    pMethod: 'GetUsrList',
  };

  static changeUserPassword: any = {
    pClass: 'VST000BO',
    pMethod: 'ChangePassword',
  };

  static setUserDetails: any = {
    pClass: 'CMS003HrBO',
    pMethod: 'SetUserDetails',
  };

  static getUserDetailsById: any = {
    pClass: 'CMS003HrBO',
    pMethod: 'GetUserDetailsById',
  };


  // System Settings
  static getSystemSettings: any = {
    pClass: 'CMS004OpnBO',
    pMethod: 'GetSettingsById',
  };

  static setSystemSettings: any = {
    pClass: 'CMS004OpnBO',
    pMethod: 'SetSettingsDatas',
  };
}

export const TEXT = {
  GENERAL_ERROR_MESSAGE: 'An Error Occured!',
  GENERAL_SUCCESS_MESSAGE: 'Saved Successfully!',
  DELETE_SUCCESS_MESSAGE: 'Deleted Successfully!',
  NO_DATA_FOUND: 'No Data Found !!!',
  DUPLICATE_ERROR: 'The {0} "{1}" is already exist!. Try a different Name',
  DUPLICATE_TEST_NOT_ALLOWED: 'The Test Already Added',
};

export enum CMS_ROUTES {
  DASHBOARD = 'dashboard',
  PATIENT_VISITS = 'patientVisits',
  INVOICES = 'invoices',
  SAMPLE_COLLECTION = 'sampleCollection',
  RESULTS_ENTRY = 'resultsEntry',
  RESULT_APPROVAL = 'resultApproval',
  LAB_REPORT = 'labReports',
}

export enum CMS_ROLES {
  ADMIN = 'Admin ',
  RECEPTIONIST = 'Receptionist',
  LAB_TECHNICIAN = 'Lab Technician',
  ACCOUNTANT = 'Accountant',
  LAB_IN_CHARGE = 'Lab Incharge',
  RESULT_APPROVER = 'Result Approver'
}

