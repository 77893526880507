import { Injectable } from '@angular/core';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { TEXT } from '../models';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(
    private dialogService: NbDialogService,
    private toaster: NbToastrService
  ) {}

  showModal(
    component: any,
    title: string,
    mode: string,
    tableName?: string,
    info?: any
  ) {
    return this.dialogService.open(component, {
      closeOnBackdropClick: false,
      closeOnEsc: true,
      hasScroll: true,
      context: {
        title: title,
        mode: mode,
        tableName: tableName,
        info: info,
      },
    });
  }

  showModalTemplate(template: any) {
    return this.dialogService.open(template, {
      closeOnBackdropClick: false,
      closeOnEsc: true,
      hasScroll: true,
    });
  }

  showErrorMessage(message: string = TEXT.GENERAL_ERROR_MESSAGE) {
    this.toaster.show(message, 'ERROR', { duration: 5000, status: 'danger' });
  }

  showWarningMessage(message: string) {
    this.toaster.show(message, 'WARNING', {
      duration: 5000,
      status: 'warning',
    });
  }

  showSuccessMessage(message: string = TEXT.GENERAL_SUCCESS_MESSAGE) {
    this.toaster.show(message, 'SUCCESS', {
      duration: 2000,
      status: 'success',
    });
  }

  showInfoMessage(message: string) {
    this.toaster.show(message, 'INFO', { duration: 2000, status: 'info' });
  }
}
