export class LabRequest {
  LabReqID: number;
  PackageID: number;
  ProfileID: number;
  TestID: number;
  ResultValue: string;

  PatientID: string;
  PatientName: string;
  PatientAge: number;
  PatientMobile: string;
  PatientSex: string;
  DoctorID: number;
  ClinicalNotes: string;
  PaymentMethodId: number;
  SubTotalAmount: number;
  DiscountAmount: number;
  TotalAmount: number;
  AdvAmount: number; // PaidAmt
  BalAmount: number;
  StatusName: string;
  SampleCollected:string; // TODO : change string to boolean on db now as 'True'/'False'
  CollectedOn:Date;
  ResultEnteredOn:Date;
  VerifiedID?: number;
}
