import { TestEntryModel } from './testEntry';

export class LabReport {
  PatientId: number;
  Name: string;
  Gender: boolean;
  Age: string;
  Mobile: string;
  LabReqNo: string;
  TestDate: Date; // Test sample collected Date
  ReportDate: Date; // Report Generated Date
  ReferenceBy: string; // Doctor Name
  AnalysisList: TestEntryModel[];
}
