export class Staff {
  StaffID: number;
  Name: string;
  Age: number;
  DOB: string;
  Gender: string;
  MobileNo: string;
  EmailID: string;
  Address: string;
  Designation: string;
  Qual: string;
  TotExp: number;
  JobDesc: string;
  DOJ: string;
  Salary: number;
  IsActive: boolean;
  Flag: string;
  CreatedBy: string;
  RoleId: number;
  RoleName: number;
}
