export class Doctor {
  DoctorID: number;
  Name: string;
  Qual: string;
  Mobile: string;
  Address: string;
  IsActive: boolean;
  createdBy: string;
  createdOn: Date;
  modifiedBy: string;
  modifiedOn: Date;
  Flag: string;
}
