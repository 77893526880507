export class SystemSettings {
  ID: number;
  ParentMenuId: string;
  Name: string;
  Desc: string;
  IsActive: string;
  IsSelected: boolean;

  constructor(init?: Partial<SystemSettings>) {
    Object.assign(this, init);
  }
}
