export class PrintSettings {
  clinicName: ElementStyle;
  addressLine1: ElementStyle;
  addressLine2: ElementStyle;
  addressLine3: ElementStyle;

  constructor() {
    this.clinicName = new ElementStyle('clinicName');
    this.addressLine1 = new ElementStyle('addressLine1');
    this.addressLine2 = new ElementStyle('addressLine2');
    this.addressLine3 = new ElementStyle('addressLine3');
  }
}

export class ElementStyle {
  elementType: 'clinicName' | 'addressLine1' | 'addressLine2' | 'addressLine3';
  fontSize: number;
  fontStyle: FontStyle;
  color: string;
  align: TextAlignment;

  constructor(
    elementType: 'clinicName' | 'addressLine1' | 'addressLine2' | 'addressLine3'
  ) {
    this.elementType = elementType;
    this.fontSize = 1; // unit is rem
    this.fontStyle = new FontStyle();
    this.color = '#222b45';
    this.align = new TextAlignment();
  }
}

export class FontStyle {
  isBold: boolean;
  isItalics: boolean;
  isUnderline: boolean;
  isNormal: boolean;

  constructor() {
    this.isBold = false;
    this.isItalics = false;
    this.isUnderline = false;
    this.isNormal = false;
  }
}

export class TextAlignment {
    left: boolean;
    center: boolean;
    right: boolean;
    justify:boolean;

    constructor() {
      this.left = true;
      this.center = false;
      this.right = false;
      this.justify = false;
    }
  }
