import { Injectable, Inject, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  @Output() onSaveCompleted = new EventEmitter<boolean>();
  @Output() onDelete = new EventEmitter();

  private baseUrl = '';

  constructor(
    private util: UtilityService,
    private http: HttpClient,
    @Inject('BASE_URL') baseURL: string
  ) {
    this.baseUrl = baseURL;
  }
  get(info: any, apiParams: any) {
    const httpOptions = this.util.getMappedHttpOptions(info, apiParams);
    return this.http.request('POST', this.baseUrl, httpOptions);
  }

  post(info: any, apiParams: any) {
    const httpOptions = this.util.getMappedHttpOptions(info, apiParams);
    return this.http.request('POST', this.baseUrl, httpOptions);
  }

  getDummyData() {
    return this.http.get<any>('assets/dummyData.json');
  }
}
