import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilityService } from './utility.service';
import {
  AnalysisItem,
  ApiParams,
  CMS_ROUTES,
  ItemModel,
  LabRequest,
  PatientVisitsStatus,
} from '../../core/models';
import { forkJoin, of } from 'rxjs';
import { AnalysisItemType } from '../models/analysis-item-types';
import { ItemsOrderSequence } from '../models/items-order-sequence';
@Injectable({
  providedIn: 'root',
})
export class LabService {
  private baseUrl = '';
  // private reqStatusList: PatientVisitStatus[] = [];

  constructor(
    private util: UtilityService,
    private http: HttpClient,
    @Inject('BASE_URL') baseURL: string
  ) {
    this.baseUrl = baseURL;
  }

  getLabReqList(info) {
    const httpOptions = this.util.getMappedHttpOptions(
      info,
      ApiParams.getLabReqsList
    );
    return this.http.request('POST', this.baseUrl, httpOptions);
  }

  getDDValuesforNewPatientVisit() {
    const lookupsHttpOptions = this.util.getMappedHttpOptions(
      undefined,
      ApiParams.getDDValuesforNewPatientVisit
    );
    const lookupResponse = this.http.request(
      'POST',
      this.baseUrl,
      lookupsHttpOptions
    );

    const packagesHttpOptions = this.util.getMappedHttpOptions(
      { Id: '' },
      ApiParams.getPackages
    );
    const packagesResponse = this.http.request(
      'POST',
      this.baseUrl,
      packagesHttpOptions
    );
    return forkJoin([lookupResponse, packagesResponse]);
  }

  getDDValuesforTestByProfID(info) {
    const httpOptions = this.util.getMappedHttpOptions(
      info,
      ApiParams.getDDValuesforTestByProfID
    );
    return this.http.request('POST', this.baseUrl, httpOptions);
  }
  // SAVE, UPDATE,DELETE
  setPatientVisit(info: any) {
    const httpOptions = this.util.getMappedHttpOptions(
      info,
      ApiParams.setPatientVisit
    );
    return this.http.request('POST', this.baseUrl, httpOptions);
  }
  getLabReqDataByID(info: any) {
    const httpOptions = this.util.getMappedHttpOptions(
      info,
      ApiParams.getLabReqDataByID
    );
    return this.http.request('POST', this.baseUrl, httpOptions);
  }

  getAllLabRequests(info: any) {
    const httpOptions = this.util.getMappedHttpOptions(
      info,
      ApiParams.getLabReqsList
    );
    return this.http.request('POST', this.baseUrl, httpOptions);
  }

  getLookupsAndLabReqByID(info: any) {
    const lookupsHttpOptions = this.util.getMappedHttpOptions(
      undefined,
      ApiParams.getDDValuesforNewPatientVisit
    );
    const lookupResponse = this.http.request(
      'POST',
      this.baseUrl,
      lookupsHttpOptions
    );

    const packagesHttpOptions = this.util.getMappedHttpOptions(
      { Id: '' },
      ApiParams.getPackages
    );
    const packagesResponse = this.http.request(
      'POST',
      this.baseUrl,
      packagesHttpOptions
    );

    const labReqHttpOptions = this.util.getMappedHttpOptions(
      info,
      ApiParams.getLabReqDataByID
    );
    const labDataResponse = this.http.request(
      'POST',
      this.baseUrl,
      labReqHttpOptions
    );

    return forkJoin([lookupResponse, packagesResponse, labDataResponse]);
  }

  setLabRequestResult(info: any) {
    const httpOptions = this.util.getMappedHttpOptions(
      info,
      ApiParams.setLabRequestResult
    );
    return this.http.request('POST', this.baseUrl, httpOptions);
  }

  getFinanceData(info: any) {
    const httpOptions = this.util.getMappedHttpOptions(
      info,
      ApiParams.getFinanceData
    );
    return this.http.request('POST', this.baseUrl, httpOptions);
  }

  getAllReqStatus() {
    // if (!this.reqStatusList.length) {
    //   const httpOptions = this.util.getMappedHttpOptions(
    //     0,
    //     ApiParams.getAllReqStatus
    //   );
    //   return this.http.request('POST', this.baseUrl, httpOptions).pipe(
    //     map((status) => {
    //       this.reqStatusList = status as [];
    //       return this.reqStatusList;
    //     })
    //   );
    // } else {
    //   return of(this.reqStatusList);
    // }
    return [];
  }

  setPatientVisitStatus(info: any) {
    const httpOptions = this.util.getMappedHttpOptions(
      info,
      ApiParams.setPatientVisitStatus
    );
    return this.http.request('POST', this.baseUrl, httpOptions);
  }

  getReqStatusIdByStatusName(statusName: string): number {
    // const status = this.reqStatusList.find((x) => x.StatusName === statusName);
    // return status!.Id;
    return 0;
  }

  /**
   *
   * @param labRequestList
   */
  getAnalyisisItemListByUsingLabRequestList(
    labRequestList: LabRequest[],
    packageList: ItemModel[],
    profileList: ItemModel[],
    testList: ItemModel[]
  ): AnalysisItem[] {
    const analysisList: AnalysisItem[] = [];

    labRequestList.forEach((req) => {
      const analysisItem = new AnalysisItem();
      if (req.PackageID) {
        //package
        const item = analysisList.find(
          (item) =>
            item.ItemType == AnalysisItemType.Package &&
            item.ItemId === req.PackageID
        );
        if (!item) {
          const packg: any = packageList.find((p) => p['ID'] === req.PackageID);
          analysisItem.ItemType = AnalysisItemType.Package;
          analysisItem.ItemId = req.PackageID;
          analysisItem.ItemName = packg?.['Text'];
          analysisItem.Amount = +(packg.Amount || 0);
          analysisItem.Children = this.addChildItems(
            new ItemModel({
              ItemType: AnalysisItemType.Package,
              ID: req.PackageID,
              ProfIdsCSV: packg.ProfIdsCSV,
              TstIdsCSV: packg.TstIdsCSV,
              ItemsOrderSeq: packg.ItemsOrderSeq,
            }),
            profileList,
            testList,
            labRequestList
          );

          analysisList.push(analysisItem);
        }
      } else if (req.ProfileID) {
        //profile
        const item = analysisList.find(
          (item) =>
            item.ItemType == AnalysisItemType.Profile &&
            item.ItemId === req.ProfileID
        );
        if (!item) {
          const profile: any = profileList.find(
            (p) => p['ID'] === req.ProfileID
          );
          analysisItem.ItemType = AnalysisItemType.Profile;
          analysisItem.ItemId = req.ProfileID;
          analysisItem.ItemName = profile?.['Text'];
          analysisItem.Amount = +(profile.Amount || 0);
          analysisItem.Children = this.addChildItems(
            new ItemModel({
              ItemType: AnalysisItemType.Profile,
              ID: req.ProfileID,
              TstIdsCSV: profile.TstIdsCSV,
            }),
            profileList,
            testList,
            labRequestList
          );

          analysisList.push(analysisItem);
        }
      } else if (req.TestID) {
        //individual Test
        const item = analysisList.find(
          (item) =>
            item.ItemType == AnalysisItemType.Test && item.ItemId === req.TestID
        );
        if (!item) {
          const test = testList.find((t) => t.ID === req.TestID);
          if (test) {
            const labReq: LabRequest | undefined = labRequestList?.find(
              (req) =>
                req.PackageID === null &&
                req.ProfileID === null &&
                req.TestID === test.ID
            );

            analysisItem.LabReqId = labReq?.LabReqID;
            analysisItem.ItemType = AnalysisItemType.Test;
            analysisItem.ItemId = req.TestID;
            analysisItem.ItemName = test?.['Text'] || '';
            analysisItem.Amount = +(test?.Amount || 0);
            analysisItem.Unit = test?.TstUnit;
            analysisItem.RefRange = test?.TstRefRange;
            analysisItem.Result = labReq?.ResultValue;
            analysisItem.Children = [];
            analysisItem.SampleCollected =
              labReq?.SampleCollected === 'True' ? true : false;
            analysisItem.Specimen = test.Specimen;
            analysisItem.VerifiedID = labReq?.VerifiedID ? true : false;
            analysisList.push(analysisItem);
          }
        }
      }
    });

    return analysisList;
  }

  addChildItems(
    selectedItem: ItemModel,
    profileList: ItemModel[],
    testList: ItemModel[],
    labRequestList?: LabRequest[]
  ): AnalysisItem[] {
    const childItems: AnalysisItem[] = [];

    if (selectedItem.ItemType === AnalysisItemType.Package) {
      (selectedItem.ItemsOrderSeq as []).forEach((item: ItemsOrderSequence) => {
        if (item.Type === AnalysisItemType.Profile) {
          const profileFound = profileList.find(
            (profile: ItemModel) => profile.ID.toString() === item.ID.toString()
          );
          if (profileFound) {
            const tests = this.getTestListOfTheProfile(
              selectedItem,
              profileFound,
              testList,
              labRequestList
            );
            childItems.push(
              new AnalysisItem({
                ItemType: AnalysisItemType.Profile,
                ItemId: profileFound.ID,
                ItemName: profileFound.Text,
                Amount: profileFound.Amount,
                Children: tests,
              })
            );
          }
        } else {
          // TEST
          const testFound = testList.find(
            (test: ItemModel) => test.ID.toString() === item.ID.toString()
          );
          if (testFound) {
            // find stand alone test in the package
            let labReq: LabRequest | undefined;
            labReq = labRequestList?.find(
              (req) =>
                req.PackageID === selectedItem.ID &&
                req.ProfileID === null &&
                req.TestID === testFound.ID
            );

            childItems.push(
              new AnalysisItem({
                LabReqId: labReq?.LabReqID,
                ItemType: AnalysisItemType.Test,
                ItemId: testFound.ID,
                ItemName: testFound.Text,
                Amount: testFound.Amount,
                Unit: testFound?.TstUnit,
                RefRange: testFound?.TstRefRange,
                Result: labReq?.ResultValue,
                SampleCollected: labReq?.SampleCollected === 'True' ? true : false,
                Specimen: testFound.Specimen,
                VerifiedID: labReq?.VerifiedID ? true : false,
                Children: [],
              })
            );
          }
        }
      });
    } else if (
      selectedItem.ItemType === AnalysisItemType.Profile &&
      selectedItem.TstIdsCSV
    ) {
      const testIds: string[] = selectedItem.TstIdsCSV.split(',');

      testIds.forEach((testId) => {
        const test = testList.find((tst) => tst.ID.toString() === testId);
        if (test) {
          let labReq: LabRequest | undefined;

          //profile
          labReq = labRequestList?.find(
            (req) =>
              req.PackageID === null &&
              req.ProfileID === selectedItem.ID &&
              req.TestID === test.ID
          );

          childItems.push(
            new AnalysisItem({
              LabReqId: labReq?.LabReqID,
              ItemType: AnalysisItemType.Test,
              ItemId: test.ID,
              ItemName: test.Text,
              Amount: test.Amount,
              Unit: test?.TstUnit,
              RefRange: test?.TstRefRange,
              Result: labReq?.ResultValue,
              SampleCollected: labReq?.SampleCollected === 'True' ? true : false,
              Specimen: test.Specimen,
              VerifiedID: labReq?.VerifiedID ? true : false,
              Children: [],
            })
          );
        }
      });
    }

    return childItems;
  }

  private getTestListOfTheProfile(
    selectedItem: ItemModel,
    profile: ItemModel,
    testList: ItemModel[],
    labRequestList?: LabRequest[]
  ): AnalysisItem[] {
    const tstList: AnalysisItem[] = [];

    const testIds: string[] =
      profile && profile.TstIdsCSV ? profile.TstIdsCSV.split(',') : [];

    testIds.forEach((testId) => {
      const test = testList.find((test) => test.ID.toString() === testId);
      if (test) {
        const labReq: LabRequest | undefined = labRequestList?.find(
          (req) =>
            req.PackageID === selectedItem.ID &&
            req.ProfileID === profile.ID &&
            req.TestID === test.ID
        );

        tstList.push(
          new AnalysisItem({
            LabReqId: labReq?.LabReqID,
            ItemType: AnalysisItemType.Test,
            ItemId: test?.ID || 0,
            ItemName: test?.Text || '',
            Amount: test?.Amount || 0,
            Unit: test?.TstUnit,
            RefRange: test?.TstRefRange,
            Formula: test?.Formula,
            Result: labReq?.ResultValue,
            SampleCollected: labReq?.SampleCollected === 'True' ? true : false,
            Specimen: test.Specimen,
            VerifiedID: labReq?.VerifiedID ? true : false,
            Children: [],
          })
        );
      }
    });
    return tstList;
  }

  getMappedPackageProfileTestList(
    packages: any[],
    profiles: any[],
    tests: any[],
    specimens?: any[]
  ): Map<string, ItemModel[]> {
    const itemsMap: Map<string, ItemModel[]> = new Map();

    const packageList: ItemModel[] = packages.map((item: any) => {
      return {
        ItemType: AnalysisItemType.Package,
        ID: item.Id,
        Code: item.Code,
        Text: item.Name,
        TstIdsCSV: item.TestCSV,
        ProfIdsCSV: item.ProfileCSV,
        TstRefRange: item.TstRefRange,
        TstUnit: item.TstUnit,
        Amount: item.PackageAmount,
        CodeAndText: item.Code + '-' + item.Name,
        ItemsOrderSeq: JSON.parse(item.ItemsOrderSeq),
      };
    });

    const profileList: ItemModel[] = profiles.map((item: ItemModel) => {
      return {
        ItemType: AnalysisItemType.Profile,
        ID: item.ID,
        Code: item.Code,
        Text: item.Text,
        TstIdsCSV: item.TstIdsCSV,
        ProfIdsCSV: '',
        TstRefRange: item.TstRefRange,
        TstUnit: item.TstUnit,
        Amount: item.Amount,
        CodeAndText: item.Code + '-' + item.Text,
      };
    });

    const testList: ItemModel[] = tests.map((item: ItemModel) => {
      return {
        ItemType: AnalysisItemType.Test,
        ID: item.ID,
        Code: item.Code,
        Text: item.Text,
        TstIdsCSV: item.TstIdsCSV,
        ProfIdsCSV: '',
        TstRefRange: item.TstRefRange,
        TstUnit: item.TstUnit,
        Amount: item.Amount,
        CodeAndText: item.Code + '-' + item.Text,
        Specimen: specimens && this.getTestSpecimen(item, specimens as []),
      };
    });

    itemsMap.set(AnalysisItemType.Package, packageList);
    itemsMap.set(AnalysisItemType.Profile, profileList);
    itemsMap.set(AnalysisItemType.Test, testList);

    return itemsMap;
  }

  getSpecimens() {
    const httpOptions = this.util.getMappedHttpOptions(
      {
        SearchText: '',
        ID: '',
        TableName: 'SPECIMEN',
      },
      {
        pClass: ApiParams.getLookupData.pClass,
        pMethod: ApiParams.getLookupData.pMethod,
      }
    );
    return this.http.request('POST', this.baseUrl, httpOptions);
  }

  saveSampleCollected(info: any) {
    const httpOptions = this.util.getMappedHttpOptions(
      info,
      ApiParams.setLabRequestSampleCollected
    );
    return this.http.request('POST', this.baseUrl, httpOptions);
  }

  saveTestResultsApproval(info: any) {
    const httpOptions = this.util.getMappedHttpOptions(
      info,
      ApiParams.setTestResultsApproval
    );
    return this.http.request('POST', this.baseUrl, httpOptions);
  }

  getPatientVisitStatusCSV(route: string): string {
    switch (route) {
      case CMS_ROUTES.DASHBOARD:
        return ''; // All Status
      case CMS_ROUTES.PATIENT_VISITS:
        return [
          PatientVisitsStatus.values.get(PatientVisitsStatus.OPEN).statusId,
          PatientVisitsStatus.values.get(PatientVisitsStatus.INVOICE_GENERATED).statusId,
        ].join();
      case CMS_ROUTES.INVOICES:
        return [
          PatientVisitsStatus.values.get(PatientVisitsStatus.INVOICE_GENERATED).statusId,
          PatientVisitsStatus.values.get(PatientVisitsStatus.SAMPLE_COLLECTED).statusId,
          PatientVisitsStatus.values.get(PatientVisitsStatus.RESULT_ENTERED).statusId,
          PatientVisitsStatus.values.get(PatientVisitsStatus.RESULT_APPROVED).statusId
        ].join();
      case CMS_ROUTES.SAMPLE_COLLECTION:
        return [
          PatientVisitsStatus.values.get(PatientVisitsStatus.INVOICE_GENERATED).statusId,
          PatientVisitsStatus.values.get(PatientVisitsStatus.SAMPLE_COLLECTED).statusId,
        ].join();
      case CMS_ROUTES.RESULTS_ENTRY:
        return [
          PatientVisitsStatus.values.get(PatientVisitsStatus.SAMPLE_COLLECTED).statusId,
          PatientVisitsStatus.values.get(PatientVisitsStatus.RESULT_ENTERED).statusId,
        ].join();
      case CMS_ROUTES.RESULT_APPROVAL:
        return [
          PatientVisitsStatus.values.get(PatientVisitsStatus.RESULT_ENTERED).statusId,
          PatientVisitsStatus.values.get(PatientVisitsStatus.RESULT_APPROVED).statusId,
        ].join();
      case CMS_ROUTES.LAB_REPORT:
        return [
          PatientVisitsStatus.values.get(PatientVisitsStatus.RESULT_APPROVED).statusId,
          PatientVisitsStatus.values.get(PatientVisitsStatus.REPORT_GENERATED).statusId
        ].join();

      default:
        return (
          PatientVisitsStatus.values.get(PatientVisitsStatus.OPEN).statusId
        );
    }
  }

  private getTestSpecimen(test: ItemModel, specimens: any[]): string {
    const specimen = specimens && specimens.find((s) => s.ID === test.SpecId);
    return specimen ? specimen['Text'] : '';
  }
}
