import { PrintSettings } from "./print-settings";

export class Clinic {
  Id: number;
  Name: string;
  Address1: string;
  Address2: string;
  Address3: string;
  Phone: string;
  Website: string;
  Email: string;
  Logo: any;
  LogoWidth: number;
  Flag: string;
  Footer1: string;
  Footer2: string;
  Footer3: string;
  Footer4: string;
  PrintSettingsObj: PrintSettings;
  PrintSettings: string; //Json of printSettingsObj

  constructor(){
    this.PrintSettingsObj = new PrintSettings();
  }
}
