import { Test } from '.';
import { AnalysisItemType } from './analysis-item-types';

export class AnalysisItem {
  LabReqId?: number;
  ItemType: AnalysisItemType;
  ItemId: number;
  ItemName: string;
  TestList: Test[];
  Amount?: number;
  Specimen?: string;
  Unit?: string;
  RefRange?: string;
  Formula?: string;
  Result?: string;
  Children?: AnalysisItem[];
  ItemGroupType?: AnalysisItemType;
  SampleCollected: boolean;
  VerifiedID: boolean;

  constructor(init?: Partial<AnalysisItem>) {
    Object.assign(this, init);
  }
}
