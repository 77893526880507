import { UserRole } from "./user-role";

export class User {
  Id: number;
  Name: string; // username
  Pswd: string;
  ConfirmPswd: string;
  OldPassword: string;
  rememberMe: boolean;
  isActive: boolean;
  createdBy: string;
  createdOn: Date;
  modifiedBy: string;
  modifiedOn: Date;
  FullName: string;
  Picture: string;
  RoleId: string;
  RoleName: string;
  Role:UserRole;
  StaffId: string;
  SignatureHeight: number;
  SignatureWidth: number;
  SignatureImage: string;

  constructor(){
    this.SignatureWidth = 150;
  }
}
