export class PaymentMethod {
  ID: string;
  Text: string;
  Code: string;
  Desc: string;
  Icon: string;

  constructor(init?: Partial<PaymentMethod>) {
    Object.assign(this, init);
  }
}
