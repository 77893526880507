export class ChangePassword {
  OldPwd: string;
  NewPwd: string;
  ConfirmPwd: string;
  Uid:number;
  
  constructor(){
    this.OldPwd = '';
    this.NewPwd = '';
    this.ConfirmPwd = '';
  }
}
