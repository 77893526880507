export class Patient {
  PatientID: string;
  GPID?: string;
  Name: string;
  Dob?: string;
  Age?: number;
  Sex?: string;
  Mobile?: string;
  Flag?: string;
  Email?: string;
  Address?: string;
}
