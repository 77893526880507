import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilityService } from './utility.service';
import { ApiParams, User } from '../models';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private baseUrl = '';
  private user: User;

  constructor(
    private util: UtilityService,
    private http: HttpClient,
    @Inject('BASE_URL') baseURL: string
  ) {
    this.baseUrl = baseURL;
  }

  get User(): User {
    return this.user;
  }

  set User(value: User) {
    this.user = value;
  }

  login(info: { LoginID: string; Password: string }) {
    const httpOptions = this.util.getMappedHttpOptions(
      info,
      ApiParams.login,
      true
    );
    return this.http.request('POST', this.baseUrl, httpOptions);
  }

  logout() {
    const httpOptions = this.util.getMappedHttpOptions(
      {
        SessionID: sessionStorage.getItem('session_id'),
        UserID: sessionStorage.getItem('u_id'),
      },
      ApiParams.login
    );
    return this.http.request('POST', this.baseUrl, httpOptions);
  }

  /**
   * Returns true if session_id is present in the local storage
   */
  isAuthenticated(): boolean {
    return sessionStorage.getItem('session_id') ? true : false;
  }
}
