import { Injectable, Inject, Output, EventEmitter } from '@angular/core';
import { SystemSettings } from '../models/system-settings';
import { Patient } from '../models';

@Injectable({
  providedIn: 'root',
})
export class SharedDataService {
  @Output() onClosePopover = new EventEmitter<boolean>();

  // used for global loading spinner
  isLoading = false;

  systemSettings: SystemSettings[];

  patient:Patient | null;

  loading(loadingStatus) {
    setTimeout(() => {
      this.isLoading = loadingStatus;
    }, 100);
  }
}
