export class Invoice {
  PatientId: number;
  Name: string;
  ReferenceBy: string;
  Gender: boolean;
  Age: string;
  Mobile: string;
  InvoiceNo: string;
  InvoiceDate: Date;
  SubTotal: number;
  Discount: number;
  Total: number;
  InvoiceItems: any[];

  // for dashboard charts
  totalSales() {
    // input dateRange - fromDate, toDate
    // available types to filter - day,weekly,monthly,yearly
    // eg : '01/05/20202' - '31/07/2020'
    const input = {
      fromDate: '01/05/20202',
      toDate: '31/07/2020',
      type: 'Monthly',
    };
    const totalSalesReport = [
      {
        date: '01/05/2020',
        totalSales: 500000,
      },
      {
        date: '01/06/2020',
        totalSales: 500000,
      },
      {
        date: '01/07/2020',
        totalSales: 500000,
      },
    ];
  }
}
