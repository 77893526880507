import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import * as moment from 'moment';
import { SelectedFile, TEXT } from '../models';
import { AsyncSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  constructor(private http: HttpClient) {}

  getHttpHeaders() {
    return new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
  }

  getMappedHttpOptions(info, apiParam, withOutSessionId?: boolean) {
    let params;
    if (withOutSessionId) {
      // eg:login
      params = new HttpParams({
        fromString:
          '___PARAM_Class=' +
          apiParam.pClass +
          '&___PARAM_Method=' +
          apiParam.pMethod,
      });
    } else {
      params = new HttpParams({
        fromString:
          '___PARAM_Class=' +
          apiParam.pClass +
          '&___PARAM_Method=' +
          apiParam.pMethod +
          '&___PARAM_SessionID=' +
          sessionStorage.getItem('session_id') +
          '&___PARAM_UserID=' +
          sessionStorage.getItem('u_id'),
      });
    }

    if (
      info &&
      (info.Flag === 'SAVE' || info.Flag === 'UPDATE' || info.Flag === 'DELETE')
    ) {
      const httpOptions = {
        headers: this.getHttpHeaders(),
        body: this.objectMould({ Args: JSON.stringify(info) }),
        params: params,
      };
      return httpOptions;
    } else {
      // for get Data
      const httpOptions = {
        headers: this.getHttpHeaders(),
        body: this.objectMould({ ObjectInfo: JSON.stringify(info) }),
        params: params,
      };
      return httpOptions;
    }
  }

  objectMould(json) {
    let data = '';
    if (json) {
      for (const key in json) {
        if (json.hasOwnProperty(key)) {
          data += key + '=' + encodeURIComponent(json[key]) + '&';
        }
      }
    }
    return data;
  }

  stringToDate(date: string) {
    if (!date) {
      return null;
    }
    return moment(date, 'DD/MM/YYYY').toDate();
  }

  dateToString(date: Date, format: string = 'DD/MM/YYYY') {
    if (!date) {
      return '';
    }
    return moment(date).format(format);
  }

  // utility to calculate the sum of a property of an array
  getSumFromArray(items: any[], prop) {
    if (!items || !items.length || !prop) {
      return 0;
    }

    return items.reduce((a, b) => {
      return parseFloat(a) + parseFloat(b[prop]);
    }, 0);
  }

  getObjCopy(obj: any) {
    let copy = Object.assign({}, obj);
    return copy;
  }

  getItemDuplicateMessage(title: string, value: string) {
    return TEXT.DUPLICATE_ERROR.replace('{0}', title).replace('{1}', value);
  }

  getSortedArrayOfObjects(
    items: any[],
    sortColumnName: string,
    sortOrder:'ASCE'|'DESC' = 'DESC'
  ): any[] {
    if (!items || !items.length) {
      return [];
    }

    if (sortOrder === 'DESC') {
      //Descending order (100,99,98...1)
      return items.sort((a, b) => b[sortColumnName] - a[sortColumnName]);
    } else {
      //Ascending order (1,2,...100)
      return items.sort((a, b) => a[sortColumnName] - b[sortColumnName]);
    }
  }

  getAge(birthDate: Date): { age: number; isInfant: boolean } {
    let today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    //check if age is less than one (infants)
    if (age === 0) {
      return { age: m, isInfant: true };
    } else {
      return { age: age, isInfant: false };
    }
  }

  getDobFromAge(age: number): Date {
    let currentYear = new Date().getFullYear();
    const dobYear = currentYear - age;
    return new Date(dobYear, 0, 1);
  }

   toFilesBase64(file: File): Observable<SelectedFile> {
    const result = new AsyncSubject<SelectedFile>();
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      const selectedFile = {
        name: file?.name,
        file: file,
        base64: reader?.result as string,
      };
      result.next(selectedFile);
      result.complete();
    };
    return result;
  }
}
