import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilityService } from './utility.service';
import { ApiParams } from '../../core/models';
@Injectable({
  providedIn: 'root',
})
export class TestService {
  private baseUrl = '';
  private httpOptions;

  constructor(
    private http: HttpClient,
    private util: UtilityService,
    @Inject('BASE_URL') baseURL: string
  ) {
    this.baseUrl = baseURL;
  }

  // Test
  addTest(obj: any) {
    // TODO : Need to replace this call with acutal data
    return this.http.post(this.baseUrl + 'post url', obj);
  }

  getTestList() {
    //const url_ = 'https://jhl.aisoftwares.org/VSTReqHandler.ashx?___PARAM_Class=CMS003HrBO&___PARAM_Method=GetDoctorList&___PARAM_SessionID=24E8AB95-6B81-4735-88C0-E1A3B5FDC28E&___PARAM_UserID=26';
    const url_ =
      'https://cms.aisoftwares.org/VSTReqHandler.ashx?___PARAM_Class=CMS003HrBO&___PARAM_Method=GetDoctorList&___PARAM_SessionID=24E8AB95-6B81-4735-88C0-E1A3B5FDC28E&___PARAM_UserID=26';

    // const params = new URLSearchParams();
    // params.set('productCode', productCode);
    let ObjectInfo = {
      DocName: '',
    };
    return this.http.post(url_, ObjectInfo, this.httpOptions);
  }

  deleteTest(id: number) {
    // TODO : Need to replace this call with acutal data
    return this.http.post(this.baseUrl + 'post url', id);
  }
}
