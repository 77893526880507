import * as moment from 'moment';

export class FilterObject {
  patientId?: string;
  patientName?: string;
  patientMob?: string;
  fromDate?: Date;
  toDate?: Date;
  statusId?: string;

  constructor(statusId: string) {
    this.patientId = '';
    this.patientName = '';
    this.patientMob = '';
    this.fromDate = moment().startOf('year').toDate();
    this.toDate = moment().toDate();
    this.statusId = statusId;
  }
}
